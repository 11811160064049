import firebase from 'firebase/app'
import { notification } from 'ant-design-vue'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/functions'
import { openNotification } from '@/services/powerAutomate'

const firebaseConfig = {
  apiKey: 'AIzaSyBcU67z2LX4ytNT1pgYp0BHqpId_HHeEmg',
  authDomain: 'skyportal2.firebaseapp.com',
  databaseURL: 'https://skyportal2.firebaseio.com',
  projectId: 'skyportal2',
  storageBucket: 'skyportal2.appspot.com',
  messagingSenderId: '660844618309',
  appId: '1:660844618309:web:90779f594b5e341153792c',
  measurementId: 'G-JP5ZHBNY4F',
}

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()
export const firestore = firebase.firestore()
export const analytics = firebase.analytics()
export const storage = firebase.storage()
export const functions = firebase.app().functions('europe-west1')

// for local testing
// functions.useFunctionsEmulator('http://localhost:5001')

// offline mode
firestore.enablePersistence({
  synchronizeTabs: true,
})

export default {
  firestore,
  storage,
  firebaseAuth,
}

export async function loginMS() {
  const provider = new firebase.auth.OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    // check this up to avoid consent each time.
    // prompt: 'consent',
    tenant: 'cfe3711d-5fa7-4907-99d3-d7d85a74bed3',
  })
  return firebaseAuth
    .signInWithPopup(provider)
    .then(() => true)
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

// export async function register(email, password, name) {
//   return firebaseAuth
//     .createUserWithEmailAndPassword(email, password)
//     .then(response => {
//       if (response.user) {
//         const { uid } = response.user
//         firestore
//           .collection('users')
//           .doc(uid)
//           .set({
//             role: '',
//             name,
//           })
//       }
//       return true
//     })
//     .catch(error => {
//       console.log(error)
//       notification.warning({
//         message: error.code,
//         description: error.message,
//       })
//     })
// }

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    console.log('true')
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        if (!user) {
          unsubscribe()
        }
        const getUserData = async () => {
          if (user) {
            let userFields = await firestore
              .collection('users')
              .doc(user.uid)
              .get()
              .then(doc => {
                return doc.data()
              })
            if (!userFields) {
              let oldId
              console.log(user)
              const userProvider = user.providerData
              if (userProvider.length) {
                userFields = await firestore
                  .collection('users')
                  .where('companyEmail', '==', userProvider[0].email)
                  .get()
                  .then(snapshot => {
                    let document
                    snapshot.forEach(doc => {
                      document = doc
                    })
                    console.log(document)
                    const data = document.data()
                    oldId = document.id
                    return data
                  })
                console.log(userFields)
                if (userFields) {
                  await firstLogin(user.uid, oldId, userFields)
                } else {
                  openNotification('Ingen tilgang', 'Du har ikke tilgang til Sky Portal. Vennligst kontakt din nærmeste leder.')
                }
              }
            }
            console.log(userFields)
            let mergedUser
            if (userFields) {
              mergedUser = Object.assign(user, {
                id: user.uid,
                name: user.displayName,
                email2: user.providerData[0].email,
                phone: userFields.phone,
                role: userFields.role,
                department: userFields.department,
                project: userFields.project,
                company: userFields.company,
                employeeID: userFields.employeeID,
                avatar: userFields.imageUrl,
                roles: userFields.roles,
                companies: userFields.companies,
                isRecruiter: userFields.isRecruiter ? userFields.isRecruiter : false,
              })
            } else {
              mergedUser = Object.assign(user, {
                id: user.uid,
                name: user.displayName,
              })
            }
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}

async function firstLogin(uid, oldId, userFields) {
  if (userFields.imageUrl) {
    // Upload the image to the new location
    const response = await fetch(userFields.imageUrl)
    const imageBlob = await response.blob()
    const newImageRef = storage.ref().child(`users/profile/${uid}`)
    await newImageRef.put(imageBlob)
    // Get the new image URL and update the userFields object
    const newImageUrl = await newImageRef.getDownloadURL()
    userFields.imageUrl = newImageUrl

    // Delete the old image
    const oldImageRef = storage.ref().child(`users/profile/${oldId}`)
    await oldImageRef.delete()
  }
  await firestore
    .collection('users')
    .doc(uid)
    .set(userFields)
  await firestore
    .collection('users')
    .doc(oldId)
    .delete()
}
