import { firestore, functions, storage } from '@/services/firebase'
import axios from 'axios'
import moment from 'moment'
import { notification } from 'ant-design-vue'

export async function create365API(data) {
  // create Office365 user API
  // check if there is already a user with the same email
  const createAADUser = functions.httpsCallable('createAADUser')
  createAADUser({
    uid: data.uid,
    body: {
      accountEnabled: true,
      displayName: data.fullName,
      mailNickname: data.fullName.replace(/\s/g, ''),
      givenName: data.name,
      surname: data.surname,
      userPrincipalName: data.companyEmail,
      jobTitle: data.title,
      mobilePhone: data.phone,
      department: data.department,
      passwordProfile: {
        forceChangePasswordNextSignIn: true,
        password: 'SkyGruppen2021!',
      },
    },
  }).then(res => {
    console.log('res', res)
    return res
  }).catch(err => {
    console.log(err)
    return err
  })
  // .then(response => {
  //   this.openNotification('Office bruker oprettett', 'Office365 bruker har blitt oprettett for ansatten.')
  // })
  // .catch(e => {
  //   this.openNotification('Feil ved oprettelse av office bruker for ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
  //   firebase.firestore.collection('users').doc(data.companyEmail).set({
  //     companyEmail: null,
  //   })
  // })
}

export async function delete365API(data) {
  const deleteAADUser = functions.httpsCallable('deleteAADUser')
  deleteAADUser({
    uid: data.uid,
    aad: data.AAD,
  })
}

export async function create365APIold(data) {
  // create Office365 user API
  const url = 'https://prod-65.westeurope.logic.azure.com:443/workflows/5938af3828fe431c861a3fa47233af1b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=fAt_EiZKTZkGgjP6eFjQj5lCNjE1AAWji8H8C9gZgOI'
  return axios.post(url, data).then(res => {
    console.log(res)
    firestore.collection('users').doc(data.uid).update({
      AAD: res.data.AAD,
    })
    return true
  })
  // .then(response => {
  //   this.openNotification('Office bruker oprettett', 'Office365 bruker har blitt oprettett for ansatten.')
  // })
  // .catch(e => {
  //   this.openNotification('Feil ved oprettelse av office bruker for ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
  //   firebase.firestore.collection('users').doc(data.companyEmail).set({
  //     companyEmail: null,
  //   })
  // })
}

export async function get365AAD(data) {
  // create Office365 user API
  const url = 'https://prod-190.westeurope.logic.azure.com:443/workflows/28aadf4ad02b4bb0b66b459a4008f56b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=tYEk43MRu_JYq0dKwH3ZoTAEBmbKP8ooW5y12wCmxXU'
  const body = {
    email: data.companyEmail,
  }
  return axios.post(url, body).then(res => {
    console.log(res)
    firestore.collection('users').doc(data.uid).update({
      AAD: res.data.AAD,
    })
    return res.data.AAD
  })
  // .then(response => {
  //   this.openNotification('Office bruker oprettett', 'Office365 bruker har blitt oprettett for ansatten.')
  // })
  // .catch(e => {
  //   this.openNotification('Feil ved oprettelse av office bruker for ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
  //   firebase.firestore.collection('users').doc(data.companyEmail).set({
  //     companyEmail: null,
  //   })
  // })
}

export async function createLeaddeskAPI(data) {
  // create Leaddesk user API
  const url = 'https://prod-250.westeurope.logic.azure.com:443/workflows/8fbead216a2448a895d98b6b895a5849/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=0_yod0xyE9DOhQI3Fk7M7ix9VOAV3m789uYLuu6OZaA'
  return axios.post(url, data).then(response => {
    openNotification('Leaddesk bruker oprettett', 'Leaddesk bruker har blitt oprettett for ansatten.')
  }).catch(e => {
    this.openNotification('Feil ved oprettelse av Leaddesk bruker for ansatt', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere')
    firestore.collection('users').doc(data.companyEmail).set({
      leadDesk: null,
    })
  })
}

export function openNotification (title, description) {
  notification.open({
    message: title,
    description,
  })
}

export async function contractAPI(data, index, user, company) {
  const date = String(moment().format('YYYY-MM-DD'))
  const url = 'https://prod-99.westeurope.logic.azure.com:443/workflows/7af479e2524e47c1a873c4fb3f993537/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Gg8MMTXmzMGMw1Hhy9LfdSQYsEYsPOnjTWygXv7yg38'
  console.log(data.positions[index], user.email2)
  const contract = {}
  return axios.post(url, {
    adress: data.adress,
    city: data.city,
    fullName: data.fullName,
    personalNumber: data.personalNumber,
    position: data.positions[index].type,
    project: data.positions[index].project,
    startDate: data.positions[index].startDate,
    zip: data.zip,
    department: data.positions[index].department,
    employmentType: data.positions[index].employmentType,
    company: data.positions[index].company,
    employeeID: String(data.employeeID),
    email: data.email,
    sentBy: user.email2,
    percentage: data.positions[index].percentage,
    notes: data.notes,
  }).then(response => {
    const fileName = 'Arbeidsavtale '.concat(data.positions[0].project, ' ', data.positions[0].employmentType, ' ', data.employeeID, ' ', data.fullName, '.pdf')
    contract.name = fileName
    const file = dataURLtoFile(response.data.document, fileName)
    return storage.ref('/signatures').child(fileName).put(file)
  }).then(snapshot => {
    console.log(snapshot.ref)
    contract.filePath = snapshot.ref.fullPath
    return snapshot.ref.getDownloadURL()
  }).then(url => {
    contract.url = url
    return firestore.collection('signatures').add({
      status: 'under approval',
      files: [
        {
          fileName: contract.name,
          filePath: contract.filePath,
          url: contract.url,
          type: 'unsigned',
        },
      ],
      title: contract.name,
      sendtBy: user.id,
      documentType: 'employment-contract',
      department: data.department,
      positionIndex: 0,
      project: data.positions[0].project,
      signed: false,
      signatureMethods: ['sms_otp'],
      signers: [
        {
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          employeeID: data.employeeID,
          id: data.employeeID,
        },
      ],
      company: company,
      createdOn: date,
    })
  }).then(signatureRef => {
    const taskRef = firestore.collection('tasks')
    return taskRef.add({
      title: `Send Arbeidsavtale til ${data.fullName}`,
      description: `${user.name} har sendt inn en ny arbeidsavtake på ${data.positions[0].project.concat(' ', data.positions[0].employmentType)} for ${data.fullName}<br>Vennligst send kontrakten til signering til:<br>${data.email}<br>${user.email2}.`,
      date,
      type: 'approve contract',
      signatureId: signatureRef.id,
      attachments: [
        {
          name: contract.name,
          url: contract.url,
          type: 'file',
        },
      ],
      callbacks: [
        {
          fn: 'sendContractAPI',
          name: 'Godkjenn og send kontrakt',
          type: 'Approval',
          data: {
            signatureId: signatureRef.id,
            sendtBy: user.id,
            contractType: 'employment contract',
            signer: {
              name: data.fullName,
              email: data.email,
            },
            file: {
              filePath: contract.filePath,
              url: contract.url,
              name: contract.name,
            },
            branding: {
              name: 'Skycall',
              logo: 'https://skycallas.no/wp-content/uploads/2020/08/cropped-skycall.png',
            },
          },
        },
      ],
    })
  }).then(res => {
    return { message: 'Kontrakt sendt til godkjenning' }
  }).catch(err => {
    console.log(err)
    const body = JSON.stringify(data).concat('<br><br>', err)
    sendEmail('vincent@skygruppen.no', '', `Feil ved genrering av kontrakt for ${data.name}`, body)
    return {
      message: 'Feil ved generering av kontrakt',
      description: 'IT har blitt informert om dette.',
    }
  })
}

// Send Email through FIrebase functions
export function sendEmail(to, cc, subject, body, importance = 'normal') {
  const sendEmail = functions.httpsCallable('sendEmail')
  return sendEmail({
    to,
    cc,
    subject,
    body,
    importance,
  })
}

// temporary solution for Onboarding of employees in Visma. Direct API in the building
export function vismaOnboarding(data) {
  const url = 'https://prod-197.westeurope.logic.azure.com:443/workflows/c9d83a0870aa49da80cc670282a34dff/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=XyWOPiJdLPVE7lldSv7j-S5jFa6_CAh_7njICEYOjNU'
  return axios.post(url, data)
}

export function tripletexOnboarding(data) {
  const tripletexCreateEmployee = functions.httpsCallable('tripletexCreateEmployee')
  tripletexCreateEmployee({
    id: data.employeeID,
    firstName: data.name,
    lastName: data.surname,
    displayName: data.fullName,
    dateOfBirth: data.birthdate,
    email: data.email,
    phoneNumberMobile: data.phone,
    nationalIdentityNumber: data.personalNumber,
    bankAccountNumber: data.bankAccount,
    userType: 'NO_ACCESS',
    address: {
      addressLine1: data.adress,
      postalCode: data.zip,
      city: data.city,
    },
  })
    .then(res => {
      return res
    })
}

export async function deactivate365API(data) {
  const url = 'https://prod-190.westeurope.logic.azure.com:443/workflows/a792b704e5774eeeb4fecebeaed2c70d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=szML5FsRz6ybB9YzTxSJlQwA_YazLbjbanLez1P6iHY'
  return axios.post(url, data)
}

export function deactivateLeaddeskAPI(data) {
  const url = 'https://prod-238.westeurope.logic.azure.com:443/workflows/04201711e9f34b878e30582f4b5b889c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_4K09aIqseDAhn7pCNjjOa75IPJ0bV7Cd11rw6WGMfk'
  return axios.post(url, {
    user: data,
  })
}

export async function addtoMailinglist(fullName, email, department) {
  const url = 'https://prod-160.westeurope.logic.azure.com:443/workflows/918b7840790043ee89d6df433418b15b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1xK2OynrppxSUTXCxM8Rgjrw9s2-gTM0o9EL7fVtblM'
  return axios.post(url, {
    email,
    fullName,
    department,
  })
}

export async function removefromMailinglist(mailingListID) {
  const url = 'https://prod-249.westeurope.logic.azure.com:443/workflows/b3aea7fd4a674af3b8029c441a9da3b8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=767LuT0-GlFypX6WEX7GZJX2N3R7XGR30nrprWu1hbs'
  return axios.post(url, {
    mailingListID,
  })
}

export async function createTripletexCSV(oldData) {
  const data = oldData
  const position = data.positions[data.positions.length - 1]
  let company, department
  await firestore.collection('settings').doc('companies').collection('companies').doc(position.company)
    .get()
    .then(doc => {
      company = doc.data()
      const division = doc.data().divisions.find(el => el.department === data.department)
      department = {
        code: data.department,
        number: division.organizationNumber,
      }
    })
  department.code = ''
  // remove +47 from phone number
  const phoneNumber = data.phone.replace('+47', '')
  console.log(company, department)
  const employeecsv = `"ANSATTNUMMER";"FORNAVN";"ETTERNAVN";"E-POST";"TELEFONNR. MOBIL";"FØDSELSDATO";"FØDSELSNUMMER";"D-NUMMER";"INTERNASJONAL ID / TYPE";"INTERNASJONAL ID / LAND";"INTERNASJONAL ID / NUMMER";"BANKKONTONUMMER";"IBAN-NUMMER";"SWIFT";"LANDET TIL DEN ANSATTES BANK";"BRUKERTYPE";"AVDELINGSNUMMER";"ADRESSELINJE 1";"ADRESSELINJE 2";"POSTNR.";"STED";"LAND";"ARBEIDSFORHOLDS-ID";"STARTDATO";"SLUTTDATO";"ÅRSAK TIL SLUTTDATO";"VIRKSOMHET";"TYPE ARBEIDSFORHOLD";"SKIPSREGISTER";"SKIPSTYPE";"FARTSOMRÅDE";"ANSETTELSESFORM";"AVLØNNINGSTYPE";"ARBEIDSTIDSORDNING";"ANTALL TIMER I SKIFTET";"YRKESKODE";"STILLINGSPROSENT";"ÅRSLØNN";"TIMELØNN";"HOVERARBEIDSGIVER (J/N)";"TIMEPRIS";"TIMEKOST";"DATO FOR FLEKSI- OG FERIESALDO";"FLEKSISALDO";"FERIESALDO"\n"${data.employeeID}";"${data.name}";"${data.surname}";"${data.email}";"${phoneNumber}";"${data.birthdate}";"${data.personalNumber}";"";"";"";"";"${data.bankAccount}";"";"";"";"3";"${department.code}";"${data.adress}";"";"${data.zip}";"${data.city}";"Norge";"";"${position.startDate}";"${position.endDate ? position.endDate : ''}";"${position.reason ? position.reason : ''}";"${department.number}";"1";"";"";"";"1";"1";"1";"";"${position.occupationCode}";"${position.percentage}";"";"";"";"";"";"";"";""`
  const storageRef = storage.ref('/tasks')
  const listRef = storageRef.child('tasksAttachments')
  const attachments = [
    {
      name: 'tripletex-import-'.concat(data.employeeID, '.csv'),
      url: '',
      type: 'file',
    },
  ]
  listRef.child(attachments[0].name).putString(employeecsv).then((snapshot) => {
    console.log(snapshot.ref)
    attachments[0].name = snapshot.ref.name
    attachments[0].fullPath = snapshot.ref.fullPath
    return snapshot.ref.getDownloadURL()
  }).then(url => {
    attachments[0].url = url
    attachments.push({
      name: 'Gå til Tripletex for å laste opp',
      url: `https://tripletex.no/execute/employeeImport?contextId=${company.tripletexContext}`,
      type: 'link',
    })
    return firestore.collection('tasks')
      .add({
        title: `Tripletex Ansatt CSV for ${data.fullName}`,
        type: 'tripletex',
        date: String(moment().format('YYYY-MM-DD')),
        icon: 'fa fa-file-excel-o',
        status: 'to-do',
        attachments,
      }).then(() => console.log('csv made')).catch(err => console.log(err))
  })
}

export async function createTripletexAPI(beforeData) {
  const data1 = beforeData
  switch (beforeData.department) {
    case 'OSL':
      data1.divisionId = 11355021
      data1.departmentId = 0
      break
    case 'TRD':
      data1.divisionId = 11355021
      data1.departmentId = 0
      break
    case 'KRS':
      data1.divisionId = 11355021
      data1.departmentId = 0
      break
  }
  data1.occupationCode = 6080 // telefonselger use tripletex occupation api endpoint when creating contract type in sky portal
  data1.percentageOfFullTimeEquivalent = data1.positions[0].percentage ? data1.positions[0].percentage : '100'
  data1.percentageOfFullTimeEquivalent = data1.positions[0].employmentType === 'Deltid' ? '20' : data1.percentageOfFullTimeEquivalent
  const createEmployeeTT = functions.httpsCallable('createEmployeeTT')
  createEmployeeTT(data1).then(res => {
    return true
  })
  data1.department = beforeData.department
}

const dataURLtoFile = function (dataurl, filename) {
  const arr = 'data:'.concat(dataurl['$content-type'], ';base64')
  const mime = arr.match(/:(.*?);/)[1]
  const bstr = atob(dataurl.$content)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export async function contractAPI2(employee, position, positionIndex, docTemplate, user, company, customFields) {
  const date = String(moment().format('YYYY-MM-DD'))
  const fileName = 'Arbeidsavtale '.concat(position.project, ' ', position.employmentType, ' ', employee.employeeID, ' ', employee.fullName, ' ', String(moment().format()))
  const destination = `signatures/${fileName}`
  let officeAddress
  let signRef
  await firestore.collection('settings').doc('departments').collection('departments').doc(position.department)
    .get()
    .then(doc => {
      console.log(doc.data())
      officeAddress = doc.data().fullAddress
    })
  const data = {
    document: {
      fullName: employee.fullName,
      personalNumber: employee.personalNumber,
      fullAddress: employee.adress.concat(', ', employee.zip, ' ', employee.city),
      startDate: String(moment(position.startDate).format('DD.MM.YYYY')),
      date: String(moment(date).format('DD.MM.YYYY')),
      officeAddress,
      employeeID: employee.employeeID,
      ...customFields,
    },
    destination,
    docTemplate,
  }
  console.log(data)
  const generateDoc = functions.httpsCallable('generateDoc')
  generateDoc(data).then(() => {
    return storage.ref(destination.concat('.docx')).getDownloadURL()
  }).then(url => {
    console.log(url)
    data.url = url
    const signatureBody = {
      status: 'under approval',
      files: [
        {
          fileName: fileName.concat('.docx'),
          filePath: destination.concat('.docx'),
          url: data.url,
          type: 'unsigned',
        },
      ],
      title: fileName,
      sendtBy: user.id,
      documentType: 'employment-contract',
      department: employee.department,
      positionIndex,
      project: position.project,
      signed: false,
      signatureMethods: ['sms_otp'],
      signers: [
        {
          name: employee.name,
          surname: employee.surname,
          email: employee.email,
          phone: employee.phone,
          employeeID: employee.employeeID,
          id: employee.employeeID,
        },
      ],
      company: company,
      createdOn: date,
    }
    return firestore.collection('signatures').add(signatureBody)
  }).then(signatureRef => {
    console.log('signatureRef', signatureRef)
    signRef = signatureRef.id
    const taskRef = firestore.collection('tasks')
    const taskBpdy = {
      title: `Send Arbeidsavtale til ${employee.fullName}`,
      description: `${user.name} har sendt inn en ny arbeidsavtake på ${position.project.concat(' ', position.employmentType)} for ${employee.fullName}<br>Vennligst send kontrakten til signering til:<br>${employee.email}<br>${user.email2}.`,
      date,
      status: 'to-do',
      type: 'approve contract',
      signatureId: signatureRef.id,
      attachments: [
        {
          name: fileName.concat('.docx'),
          url: data.url,
          type: 'file',
        },
      ],
      callbacks: [
        {
          fn: 'sendContractAPI',
          name: 'Godkjenn og send kontrakt',
          type: 'CompleteTask',
          data: {
            signatureId: signatureRef.id,
            sendtBy: user.id,
            contractType: 'employment contract',
            signer: {
              name: employee.fullName,
              email: employee.email,
            },
            file: {
              filePath: destination.concat('.docx'),
              url: data.url,
              name: fileName.concat('.docx'),
              format: 'docx',
            },
            branding: {
              name: 'Skycall',
              logo: 'https://skycallas.no/wp-content/uploads/2020/08/cropped-skycall.png',
            },
          },
        },
      ],
    }
    console.log(taskBpdy)
    return taskRef.add(taskBpdy)
  }).then(res => {
    docx2PDFpa(data.url, fileName, employee, user)
    // add signref to positions in employee
    console.log('Employee UID', employee, employee.uid)
    const employeeRef = firestore.collection('users').doc(employee.uid)
    employee.positions[positionIndex].signRef = signRef
    console.log({ signRef })
    employeeRef.update({
      positions: employee.positions,
    })
    return { message: 'Kontrakt sendt til godkjenning' }
  }).catch(err => {
    console.log(err)
    const body = JSON.stringify(data).concat('<br><br>', err)
    sendEmail('vincent@skygruppen.no', '', `Feil ved genrering av kontrakt for ${data.name}`, body)
    return {
      message: 'Feil ved generering av kontrakt',
      description: 'IT har blitt informert om dette.',
    }
  })
}

export async function docx2PDFpa(fileUrl, fileName, employee, user) {
  const docx = await getBase64Docx(fileUrl)
  // const docx = await storage.ref(fileUrl).getBlob()
  const url = 'https://prod-146.westeurope.logic.azure.com:443/workflows/fb0e00af6f604aac9e15e131c1b6b8d6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6o3FzGlt7HIxJA7DaaiwCsmC138qQ1AAwCCM8WPIzfQ'
  // console.log(docx, employee, user)
  axios.post(url, {
    docx,
    title: fileName,
    fullName: employee.fullName,
    email: employee.email,
    sentBy: user.email2,
    notes: employee.notes,
  })
}

const getBase64Docx = async (fileUrl) => {
  const image = await axios.get(fileUrl, { responseType: 'arraybuffer' })
  return Buffer.from(image.data).toString('base64')
}

// export function sendDocumentForSignature () {
//   firestore.collection('signatures').add({
//     status: 'under approval',
//     files: [
//       {
//         fileName: fileName.concat('.docx'),
//         filePath: destination.concat('.docx'),
//         url: data.url,
//         type: 'unsigned',
//       },
//     ],
//     title: fileName,
//     sendtBy: user.id,
//     documentType: 'employment-contract',
//     department: employee.department,
//     positionIndex,
//     project: position.project,
//     signed: false,
//     signatureMethods: ['sms_otp'],
//     signers: [
//       {
//         name: employee.name,
//         surname: employee.surname,
//         email: employee.email,
//         phone: employee.phone,
//         employeeID: employee.employeeID,
//         id: employee.employeeID,
//       },
//     ],
//     company: company,
//     createdOn: date,
//   }).then(signatureRef => {
//     const taskRef = firestore.collection('tasks')
//     taskRef.add({
//       title: `Send Arbeidsavtale til ${employee.fullName}`,
//       description: `${user.name} har sendt inn en ny arbeidsavtake på ${position.project.concat(' ', position.employmentType)} for ${employee.fullName}<br>Vennligst send kontrakten til signering til:<br>${employee.email}<br>${user.email2}.`,
//       date,
//       status: 'to-do',
//       type: 'approve contract',
//       signatureId: signatureRef.id,
//       attachments: [
//         {
//           name: fileName.concat('.docx'),
//           url: data.url,
//           type: 'file',
//         },
//       ],
//       callbacks: [
//         {
//           fn: 'sendContractAPI',
//           name: 'Godkjenn og send kontrakt',
//           type: 'CompleteTask',
//           data: {
//             signatureId: signatureRef.id,
//             sendtBy: user.id,
//             contractType: 'employment contract',
//             signer: {
//               name: employee.fullName,
//               email: employee.email,
//             },
//             file: {
//               filePath: destination.concat('.docx'),
//               url: data.url,
//               name: fileName.concat('.docx'),
//               format: 'docx',
//             },
//             branding: {
//               name: 'Skycall',
//               logo: 'https://skycallas.no/wp-content/uploads/2020/08/cropped-skycall.png',
//             },
//           },
//         },
//       ],
//     })
//     return signatureRef.id
//   })
// }
