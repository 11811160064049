<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { mapState } from 'vuex'
import norwegian from '@/locales/nb-NO'
import english from '@/locales/en-US'

const locales = {
  'nb-NO': norwegian,
  'en-US': english,
}

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'nb-NO',
  fallbackLocale: 'nb-NO',
  messages: {
    'nb-NO': locales['nb-NO'].messages,
    'en-US': locales['en-US'].messages,
  },
})

export default {
  name: 'Localization',
  mounted() {
    this.$i18n.locale = this.settings.locale
  },
  computed: {
    ...mapState(['settings']),
    locale() {
      return locales[this.settings.locale].localeAntd
    },
  },
  watch: {
    'settings.locale': function (value) {
      this.$i18n.locale = value
    },
  },
}
</script>
