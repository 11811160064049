<template>
  <div :class="$style.topbar">
    <!-- <div class="mr-4">
      <cui-fav-pages />
    </div> -->
    <div class="mr-auto">
      <!-- <cui-search /> -->
    </div>
    <!-- <div class="mr-4 d-none d-md-block">
      <cui-issues-history />
    </div> -->
    <!-- <div class="mb-0 mr-auto d-xl-block d-none">
      <cui-project-management />
    </div> -->
    <!-- <div class="mr-4 d-none d-sm-block">
      <cui-language-switcher />
    </div> -->
    <!-- <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div> -->
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
// import CuiSearch from './Search'
import CuiUserMenu from './UserMenu'

export default {
  components: {
    // CuiLanguageSwitcher,
    // CuiActions,
    CuiUserMenu,
    // CuiSearch,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
