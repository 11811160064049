import localeAntd from 'ant-design-vue/lib/locale-provider/nb_NO'

const messages = {
  topBar: {
    issuesHistory: 'Feil Hystorikk',
    projectManagement: 'Prosjekt Styring',
    typeToSearch: 'Søk...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hei',
      billingPlan: 'Betalings Plan',
      role: 'Rolle',
      email: 'E-post',
      phone: 'Telefon',
      editProfile: 'Endre Profil',
      logout: 'Logg ut',
    },
  },
  general: {
    new: 'Ny',
    edit: 'Endre',
    submit: 'Lagre',
    cancel: 'Avbryt',
  },
  userInfo: {
    employee: 'ansatt',
    name: 'Navn',
    surname: 'Etternavn',
    sex: 'Kjønn',
    birthdate: 'Fødselsdato',
    age: 'Alder',
    citizenship: 'Statsborgerskap',
    socialsecurity: 'Fødselsnummer',
    bankaccount: 'Kontonummer',
    adress: 'Adresse',
    city: 'By',
    zip: 'Postnummer',
    phone: 'Telefon',
    email: 'E-post',
    startDate: 'Start Dato',
  },
}

export default {
  locale: 'no-NB',
  localeAntd,
  messages,
}
