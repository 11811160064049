import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import * as firebase from '@/services/firebase'

const mapAuthProviders = {
  firebase: {
    loginMS: firebase.loginMS,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    department: '',
    project: '',
    employeeID: '',
    email: '',
    email2: '',
    avatar: '',
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
    roles: [],
    companies: [],
    isRecruiter: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const login = mapAuthProviders[rootState.settings.authProvider].login
      login(email, password).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Logget inn',
            description: 'Du er nå logget inn på Sky Portal',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOGINMS({ commit, dispatch, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const login = mapAuthProviders[rootState.settings.authProvider].loginMS
      login().then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Logget inn',
            description: 'Du er nå logget inn på Sky Portal',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders[rootState.settings.authProvider].register
      register(email, password, name).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
      currentAccount().then(response => {
        if (response) {
          const { id, email, employeeID, email2, name, avatar, role, roles, companies, isRecruiter, department, project, position } = response
          commit('SET_STATE', {
            id,
            name,
            employeeID,
            email,
            email2,
            avatar,
            role,
            department,
            project,
            roles,
            companies,
            isRecruiter,
            position,
            authorized: true,
          })
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      logout().then(() => {
        commit('SET_STATE', {
          id: '',
          name: '',
          role: '',
          roles: [],
          companies: [],
          isRecruiter: [],
          department: '',
          project: '',
          employeeID: '',
          email: '',
          email2: '',
          avatar: '',
          authorized: false,
          loading: false,
        })
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: state => state,
  },
}
