<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Localization from '@/localization'

export default {
  name: 'app',
  components: { Localization },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  mounted() {
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  created () {
    document.addEventListener(
      'swUpdated', this.showRefreshUI, { once: true },
    )
    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      },
    )
  },
  methods: {
    showRefreshUI (e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      console.log(e.detail)
      this.registration = e.detail
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>
